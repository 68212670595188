.status__clip {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: var(--color-bg-100-light);
  line-height: 12px;
  margin-left: -4px !important;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.statusbg {
    &__pending {
      .inner__status {
        background: var(--color-text-50-light);
        margin-left: -1px;
        margin-right: -1px;
      }
      svg > path {
        fill: var(--color-text-50-light);
      }
    }
    &__processing {
      .inner__status {
        background: #8c97b8;
      }
      svg > path {
        fill: #8c97b8;
      }
    }
    &__failed,
    &__rejected {
      .inner__status {
        background: var(--color-rejected);
      }
      svg > path {
        fill: var(--color-rejected);
      }
    }
    &__completed {
      .inner__status {
        background: var(--color-green);
      }
      svg > path {
        fill: var(--color-green);
      }
    }
  }

  &:first-child {
    margin-left: 0px !important;
    .leftImg {
      display: none;
    }
  }

  .inner__status {
    padding: 4px 4px;
    height: 30px;
    /* width: 21px; */
    font-size: 12px;
    display: flex;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 100%;
  }
}
