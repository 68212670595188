.calender-btn {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  background-color: var(--color-bg-100-light);
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  cursor: pointer;
  padding: calc(var(--base-padding) / 2.7);
  color: var(--color-text-90-dark);
  gap: calc(var(--base-margin) * 0.75);
  border: 1px solid var(--color-input-border-light);
  border-radius: calc(var(--base-border-radius) * 0.5);
  height: 46px;

  i {
    font-size: var(--font-size-6);
    color: var(--color-text-40-light);
  }
  i::before {
    font-size: var(--font-size-6);
    color: var(--color-text-40-light);
  }
  .selected-range {
    white-space: nowrap;
    display: flex;
    font-weight: 500;
  }
  .selected-range--date {
    font-weight: 500;
  }
  .selected-range--to {
    font-weight: 400;
    margin: 0 4px;
    color: #8e98a5;
  }
  .arrow-icon--active {
    transform: rotate(180deg);
    transition: transform 0.25s;
  }
  .ri-arrow-down-s-line {
    transition: transform 0.25s;
  }
}

.calender-btn:hover {
  border-color: var(--color-primary-light);
  .Date-picker--calendar-icon::before {
    color: var(--color-primary-light);
  }
}

.calender-btn--active {
  border-color: var(--color-primary-light);
  .Date-picker--calendar-icon::before {
    color: var(--color-primary-light);
  }
}
