@import "../../../styles/helpers.sass";

.sba7-bid-sheet {
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  max-height: 400px;
  display: flex;
  flex-direction: column;

 

  &__heading {
    font-size: 20px;
    font-weight: 600;

  
  }

  &__desc {
    font-size: 14px;
    color: $n67;
    font-weight: 400;
   
  }

  .react-table-responsive-container {
    display: flex;
    width: 10px;
    min-width: 100%;
    border: 1px solid $n6;
    border-radius: 4px;
    height: 100%;
    // width: 100%;
    flex: 1 1;
    overflow-y: auto;
   
  }

  .react-table-responsive-wrapper {
    overflow: unset;
    min-height: unset;
    tbody tr,
    tbody tr:last-child {
      background: transparent !important;
      border-bottom: 1px solid #d9e0f2;
    }

    thead tr {
      background: $n7 !important;
      height: 52px;
    }

    th, td {
      border: 1px solid $n6;
      padding: 0 8px;
      color: var(--color-black);
      > div {
            white-space: nowrap;
          }
    }
  }

  .text-elipsis {
    max-width: unset;
  }

  thead tr th {
    color: #777e90;
  }

  button {
    font-size: 12px;
    border-radius: 50px;
  }



  .react-table-responsive__head tr th {
    text-overflow: unset;
    max-width: unset;
  }
  /* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Change the width as desired */
  height: 10px; /* Change the height as desired */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}


}

/* For Firefox */
html {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
}

/* Optional: General scrollbar styling for other browsers */
body {
  overflow-y: scroll; /* Ensures scrollbars are always visible */
  scrollbar-base-color: #888;
  scrollbar-face-color: #888;
  scrollbar-track-color: #f1f1f1;
}