@import "../../../../styles//helpers.sass";

.mobile-link {
  width: 100vw;
  height: 100vh;
  background-color: $white;

  .mobile-verification__error,
  .mobile-verification__success {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;

    .mobile-verification__success-message {
      font-size: 18px;
      color: $dark;
    }
  }

  .mobile-approve {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__container {
      display: flex;
      width: 350px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    &__header {
      color: $dark;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }

    img {
      height: 144px;
      object-fit: contain;
      width: 144px;
    }

    &__description {
      color: var(--color-text-60-light);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      margin-top: 15px;

      button {
        background: #F2F4F7;
        border-radius: 4px;
        border: none;
        padding: 16px;
        cursor: pointer;
        color: #2F323D;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &__actions-close {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 120px;
      height: 48px;
      margin-top: 15px;

      button {
        background: #F2F4F7;
        border-radius: 4px;
        border: none;
        padding: 16px;
        cursor: pointer;
        color: #2F323D;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
