.mint-detail-table {
  &.table-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-width: 100%;
    border: 1px solid #ddd;
    margin: 16px 0;
  }
  .table-header {
    div {
      font-size: 12px;
    }
  }
  .table-row div {
    color: #353945;
    font-size: 14px;
    font-weight: 500;
  }
  .table-header,
  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    > div {
      padding: 0 4px;
      width: 20%;
      &.head-email {
        width: 30%;
      }
    }
  }

  .table-row {
    border-top: 1px solid #ddd;
  }

  input[type="checkbox"] {
    margin-right: 4px;
  }
}
