.chat-list__container {
  display: flex;
  flex-direction: column;
  width: 350px;
  border-right: 1px solid #0000001F;
 
  .chat-list__header {
    padding: 20px 16px 20px 16px;
    gap: 10px;
    border-bottom: 1px solid #0000001F;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #1F1F1F;
    }
  }

  .chat-list__filter {
    padding: 16px;
    gap: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .input__group {
    height: 44px;
  }

  .chat-list__body {
    max-height: 550px;
    overflow-y: auto;
    .loader-blue{
      margin: 20px;
    }

    .chat-panel {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;
      cursor: pointer;
      transition: 0.2s ease-in;

      &:hover {
        background-color: #F5F5F5;
      }

      .image-container{
        position: relative;
        display: inline-block;

        /* Circle dot styling */
        .circle-dot {
          position: absolute;
          width: 15px;
          height: 15px;
          background-color: #109345;
          border-radius: 50%;
          top: 85%; /* Adjust this value to position the dot vertically */
          left: 85%; /* Adjust this value to position the dot horizontally */
          transform: translate(-50%, -50%); /* Center the dot exactly */
          border: 2px solid white; /* Optional: adds a border around the dot */
      }

      }
      
      .name-initial {
        width: 48px;
        height: 48px;
        background-color: #D6D6D6;
        font-size: 19px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: white;
      }

      .chat-personal-details {
        h3 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1F1F1F;
          text-transform: capitalize;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #7A7A7A;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          max-width: 300px;
          overflow: hidden;
          max-width: 250px;
        }
      }
    }

    .active-panel {
      background-color: #F5F5F5;
    }
  }
}