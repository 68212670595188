@import "../../../styles/helpers.sass";

.sba7-details-container {
  padding: 10px;
  .sba7-details-heading {
    font-size: 20px;
    padding: 0 10px 15px;
    font-weight: 600;
  }

  .sba7-details-desc {
    font-size: 14px;
    color: $n67;
    font-weight: 400;
  }

  .sba7-details-image {
    width: 100%;
    height: 380px;
    border-radius: 8px;
    border: 1px solid $n6;
    background-color: $n6;
    display: flex;
    justify-content: center;
    align-items: center;

    .ri-image-add-line {
      font-size: 50px;
      color: $n4;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  .sba7-details {
    background-color: $white;
    padding: 15px 0;

    .sba7-name {
      font-size: 16px;
      font-weight: 600;
      width: 30ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sba7-details-and-logo {
      display: flex;
      align-items: start;
      margin-bottom: 8px;

      .logo-img {
        width: 52px;
        height: 52px;
        border: 1px solid $n6;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .ri-file-upload-line {
          font-size: 20px;
          color: $n4;
        }

        &.with-bg {
          background-color: $n6;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .skeleton_table {
        height: 26px;

        .skeleton_table_symbol {
          max-width: 250px;
        }
      }
    }

    .title-heading-symbol {
      padding-left: 10px;
    }

    .title-symbol {
      font-size: 14px;
      color: $n4;
      font-weight: 500;
    }

    .sba7-desc {
      font-size: 14px;
      font-weight: 400;
      color: $n4;

      &.skeleton_table {
        margin: 0;
        height: 26px;

        .skeleton_table_symbol {
          max-width: 100%;
          height: 25px;
        }
      }
    }
  }

  .sba7-detail-stats {
    margin: 5px 10px 15px;
    border-block: 1px solid $n6;
    padding-block: 10px;

    .sba7-details-heading {
      padding-left: 0;
    }

    .stats-details {
      display: grid;
      gap: 15px;
      grid-template-columns: 4fr 4fr 4fr;
    }

    .stat-label {
      font-size: 14px;
      color: $n4;
      font-weight: 400;
      text-transform: capitalize;
    }

    .stat-value {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      color: var(--color-black);
    }
  }

  .data-room-files-container {
    margin-inline: 10px;
  }

  .sba7-bid-sheet {
    margin-inline: 10px;
  }

  .middle-divider {
    border-top: 1px solid $n6;
    margin: 20px 10px 15px;
  }

}
