.accountdropdown-list{
  .ellipsismenu{
    .ellipsismenu__options-box-bottom{
      padding: 0px;
      right: 16px;
      top: 20px;
    }
    .ellipsismenu__menu-item{
      color: #5D657A;
      background-color: transparent;
    }
  }
  .disablebtn{
    pointer-events: none;
  }
}

.disable{
  cursor: not-allowed;
}

.session-modal{
  z-index: 9999 !important;
  max-width: 1300px !important;
  min-width: 1300px !important;
  .Modal__wrapper{
    max-height: 700px;
    padding: 0 24px !important;
    overflow-y: auto;
  }
  .kycDetails:nth-child(2){
    max-height: 600px !important;
    overflow-y: auto;
  }
  .session-column .activity-sticky{
    top: 0 !important;
  }
}
