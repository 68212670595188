@import styles/helpers

.form
    background: $white
.nav
    display: flex
    justify-content: center
    margin-bottom: 32px
    .link
        display: flex
        padding: 6px 12px
        border-radius: 14px
        background: none
        +button-2
        color: $n4
        transition: all .2s
        &:hover
            color: $n3
            +dark
                color: $n8
        &.active
            background: $n3
            color: $n8
            +dark
                background: $n3
                color: $n8
        &:not(:last-child)
            margin-right: 24px

.container
    margin-bottom: 32px
.edit
    text-align: left
    margin-bottom: 16px
    display: flex
    flex-direction: column
    color: $dark
    width:480px
    font-size: 16px
    line-height:24px
    font-weight: 400

.resend
    margin-bottom: 16px
    display: flex
    justify-content: center
    color: $dark
    width:480px
    padding: 12px 24px 12px 24px

.editBtn    
    cursor: pointer
    margin-left:4px
    text-decoration: underline 
    width: 36px 
    color: #F5C462
    &:hover
        color: $p1 
        font-weight: 700

.line
    display: flex
    justify-content: center
    gap: 10px
    .field
        &:first-child
            flex-shrink: 0
            width: 128px
            margin-right: 8px
        &:nth-child(2)
            flex-grow: 1
    .dropdown
        color: $dark

.foot
    display: flex
    justify-content: space-between
    margin-top: 16px
    +caption-bold-2
    .link
        color: $p1
        transition: color .2s
        &:hover
            color: darken($p1, 10)

.scan
    +caption-bold-2
    color: $n4
    transition: color .2s
    &:hover
        color: $n2

.buttons
    width: 480px
    height: 48px
    padding: 12px, 16px, 12px, 16px
    border-radius: 8px
    gap: 10px
    font-size: 14px
    font-weight: 500
    line-height: 24px
    letter-spacing: 0em
    background: #0051CC
    color:$white




.otpInput 
    background-color: transparent
    border: 1px solid $n6
    border-radius: 10px
    color: $dark
    font-weight: 700
    height: 58px
    text-align: center
    width: 58px

.verify_button
    display: flex
    justify-content: center
    width: 480px
    margin-top: 12px

.otp    
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.otp_detail
    font-weight: 600
    font-size: 32px
    color: $dark
    width: 480px
    text-align: left
    +m
        font-size: 24px
        text-align: left

.back_button
    border-radius: 0 !important
    width: 480px
    background: grey
    &:hover
        background: rgba(245, 175, 69, 0.06) 

.email
    color:$dark
    font-size: 16px
    line-height:24px
    font-weight: 500



.resend
  margin-bottom: 16px
  display: flex
  justify-content: center
  color: $dark
  width:100%
  text-align: center
  padding: 12px 24px 12px 24px
  font-size: 14px
  line-height: 24px
  font-weight: 400



.editBtn  
  cursor: pointer
  margin-left:4px
  text-decoration: underline 
  width: 36px 
  // color: #F5C462
  color: #0051CC
  font-weight: 500

  &:hover
    color: $p1 
    font-weight: 700
  
