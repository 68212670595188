.admin-comment{
    color: #1F1F1F !important;
    font-weight: 500;
    display: inline;

}
.admin-file-comment{
    margin: -6px 25px 0px 50px;
    font-size: 12px;
    font-weight: 400;
    font-family: Poppins;
}

.history-row{
    .tooltip{
        max-width: 500px;
        text-wrap: wrap;
    }
}