@import "../../styles/helpers.sass";

.data-room-files-container {
  .no-data-found-mp {
    align-items: center;
    color: #353945;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 600;
    gap: 10px;
    justify-content: center;
    padding: 15px;
    img {
      width: unset;
      height: unset;
    }
  }
  .data-room-heading {
    font-size: 20px;
    padding-bottom: 15px;
    font-weight: 600;

    @include dark {
      color: $white;
    }
  }

  .data-room-desc {
    font-size: 14px;
    color: $n67;
    font-weight: 400;
    @include dark {
      color: $n5;
    }
  }

  .data-room-files {
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 10px;
    padding: 15px;

    .data-room-item {
      display: flex;
      gap: 15px;
      align-items: center;

      .data-room-img {
        width: 50px;
        height: 50px;
        border: 1px solid $n5;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .data-room-details {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: start;
      word-break: break-all;
      flex: 1 1;

      .name {
        cursor: pointer;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        font-size: 12px;
        color: $n4;
      }
    }

    .button {
      border-radius: 4px;
      height: 24px;
      min-height: 24px;
      font-weight: 500;
      font-size: 14px;
      padding-inline: 15px;
      &.btn-light-blue {
        background: #3c65d60f;
        color: #3c65d6;
      }
    }
  }

  @include dark {
    .data-room-details {
      span {
        color: $n5;
      }
    }
  }
}
