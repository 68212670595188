.date-range-picker-overlay {
  z-index: 3;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.date-range-picker {
  display: flex;
  gap: calc(var(--margin) * 0.25);
  width: fit-content;
  padding: calc(var(--padding) * 0.5);
  border-radius: calc(var(--border-radius) * 0.75);
  background-color: var(--color-bg-100-light);
  box-shadow: 0px 4px 60px rgb(0 0 0 / 10%);
  position: absolute;
  top: 124px;
  right: 230px;
  z-index: 4;
  min-width: 450px;
}

.overlay-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--margin) * 0.25);
  padding-bottom: calc(var(--padding) * 0.5);
  padding-top: calc(var(--padding) * 0.75);
}

.rdrCalendarWrapper {
  background-color: var(--color-transparent);
}

.rdrInputRanges {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 130px !important;
  border: none !important;
  background: unset !important;
}

.rdrMonthAndYearWrapper {
  background-color: var(--color-bg-blue-dark);
  color: var(--color-text-100-dark);
}

.rdrWeekDay {
  color: var(--color-golden) !important;
}

.rdrMonths {
  background-color: var(--color-bg-blue-dark);
}

.rdrDay .rdrDayNumber span {
  color: var(--color-text-100-dark);
}

.rdrDayDisabled {
  background-color: var(--color-transparent) !important;
}

.rdrNextPrevButton {
  background: var(--color-transparent) !important;
}

.DefinedRange--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: 400 !important;
  color: var(--color-bg-100-light) !important;
  background-color: var(--color-primary-light);
}

.rdrStaticRangeLabel {
  padding: 6px 20px !important;
}
