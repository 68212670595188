

.ellipsismenu {
  position: relative;
  &__icon-container{
    cursor: pointer;
    color: #747E99;
    margin-top: 4px;
  }
  &__options-box-bottom {
    position: absolute;
    right: 15px;
    min-width: 168px;
    background: #fff;
    top: 0;
    opacity: 22;
    padding: 12px 0;
    border-radius: 8px;
    min-height: 180;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    z-index: 1;
   
  }
  &__options-box-top{
    position: absolute;
    right: 15px;
    min-width: 168px;
    background: #fff;
    bottom: 0;
    opacity: 22;
    padding: 12px 0;
    border-radius: 8px;
    min-height: 180;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    z-index: 1;
   
  }
  &__menu-item {
    font-size: 14px;
    align-items: center;
    z-index: 1;
    font-weight: 500;
    line-height: 20px;
    color: #5d657a;
    width: 100%;
    border: none;
    background-color: unset;
    padding: 4px 8px;
    display: flex;
    gap: 4px;
    height: 40px;
  }
  &__menu-item:hover {
    background-color: #F4F5F6;
    border-radius: 2px;
    transition: all 0.3s;
    cursor: pointer;
  }
}
