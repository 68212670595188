.range-slider {
  border-radius: 8px;
  height: 2.5px;
  width: 356px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #fff;
  box-shadow: 0px 0.320776px 2.56621px rgba(0, 0, 0, 0.12),
    0px 3.84931px 8.34017px rgba(0, 0, 0, 0.12);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
