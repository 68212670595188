.detail-table {
  &.table-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-width: 100%;
    border: 1px solid #ddd;
    margin: 16px 0;
  }

  .table-header,
  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    > div {
      flex: 1 1;
      padding: 0 12px;
    }
  }

  .table-row {
    border-top: 1px solid #ddd;
  }

  input[type="checkbox"] {
    margin-right: 4px;
  }
}
