.roles-and-permission {
  background: #f0f4ff;
  min-height: 100vh;
  padding-bottom: 48px;
  .roles-list {
    display: flex;
    padding: 12px 20px 0;
    flex-wrap: wrap;
    gap: 20px;
  }
  .role-details {
    height: 98vh;
    padding: 24px;
    width: 100%;
    .role--details {
      display: flex;
      height: 88%;
      .modal-des span {
        color: #6c707a;
        text-align: left;
      }
      .modal-footer {
        justify-content: end;
        padding: 16px 24px;
        gap: 12px;
        border-top: 1px solid #e1e6f5;
        border-bottom-left-radius: calc(var(--padding) * 0.75);
        border-bottom-right-radius: calc(var(--padding) * 0.75);
      }
      .permissions {
        width: 70%;
        background: #ffffff;
        padding: 16px;
        margin-right: 16px;
        height: 100%;
        min-height: 650px;
        h4 {
          margin-top: 16px;
        }
        h3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .right-header {
            display: flex;
            align-items: center;
          }
        }
        .button-label-txt {
          font-size: 14px;
          width: 100%;
        }
        button {
          width: 58px;
          height: 28px;
          min-height: unset;
          margin-right: 8px;
        }
      }
      .assigned-user {
        min-height: 650px;
        background: #ffffff;
        width: 29%;
        margin: 16px 0 0;
        height: 100%;
        padding: 16px;
        .assigned--user {
          max-height: 71vh;
          overflow: scroll;
          height: 100%;
        }
        .count {
          color: #747e99;
          font-weight: 500;
        }
        .asigned-user-row {
          font-size: 14px;
          display: flex;
          font-weight: 500;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
          position: relative;
          .disablebtn {
            pointer-events: none;
          }
          .ri-more-2-line {
            cursor: pointer;
          }
          .disable {
            cursor: not-allowed;
          }
          .userAction {
            position: absolute;
            right: 15px;
            top: 22px;
            color: #5d657a;
            box-shadow: 0px 0px 4px 0px #0000001f;
            padding: 8px;
            border-radius: 4px;
            font-size: 14px;
            z-index: 9;
            background: white;
          }
          .userAction-row {
            padding: 8px 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
              font-size: 20px;
              margin-right: 8px;
            }
          }
        }
        .asigned-user--row {
          display: flex;
          align-items: center;
        }
        .pending {
          white-space: nowrap;
          width: 124px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .tool-tip {
          z-index: 999999;
          color: #ffffff;
          width: 224px;
          white-space: pre-line;
          overflow: hidden;
          word-break: break-all;
          overflow-wrap: break-word;
        }
        .accepted {
          white-space: nowrap;
          width: 212px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .asigned-user--right {
          display: flex;
          align-items: center;
        }
        .invite-status {
          font-size: 12px;
          text-transform: capitalize;
          background: #b45c0420;
          color: #b45c04;
          border-radius: 50px;
          padding: 3px 12px;
          margin-right: 8px;
          &.blocked {
            color: #d32222;
            background: #d3222220;
          }
        }
        .user-icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          background: aliceblue;
          margin-right: 12px;
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            margin: 0;
            color: #3c65d6;
          }
        }
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          margin: 8px 0;
        }
        .empty-user {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          h3 {
            justify-content: center;
          }
        }
        button {
          margin: 0 auto;
        }
      }
    }
    .backTo {
      color: #747e99;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
  }
  &--empty-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    button {
      margin: 0 auto;
      border-radius: 50px;
    }
    h2 {
      font-size: 16px;
      color: #464c5c;
      font-weight: 600;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: #747e99;
      margin: 12px 0;
    }
  }
  .role-card {
    width: 32%;
    background: white;
    border-radius: 8px;
    margin: 0;
    padding: 16px;
    display: flex;
    align-items: baseline;
    position: relative;
    cursor: pointer;
    .role--card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card-more {
      position: absolute;
      right: 8px;
      font-size: 24px;
      top: 12px;
      color: #747e99;
      .disablebtn {
        pointer-events: none;
      }
    }
    .disable {
      cursor: not-allowed;
    }
    .templateRoles {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .userAction {
      position: absolute;
      right: 25px;
      top: 30px;
      color: #5d657a;
      box-shadow: 0px 0px 4px 0px #0000001f;
      padding: 8px;
      border-radius: 4px;
      font-size: 14px;
      z-index: 9;
      background: white;
    }
    .userAction-row {
      padding: 8px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .card-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      align-items: center;
    }
    button {
      color: #3c65d6;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin: 8px 0;
      color: #747e99;
    }
    .template-role--label {
      background: rgba(245, 69, 174, 0.1607843137);
      font-size: 12px;
      font-weight: 600;
      color: #f545ae;
      padding: 5px 12px;
      border-radius: 4px;
      display: table;
      &.not-all-access {
        background: #7935de12;
        color: #7935de;
        margin-top: 16px;
      }
    }
    .circleNames {
      display: flex;
      position: relative;

      img {
        max-width: 20px;
      }
    }
    .circleName {
      position: relative;
      margin-left: -12px;

      &:first-child {
        margin: 0;
      }
      &:last-child {
        cursor: pointer;
      }
    }
    .circleNameS {
      width: 35px;
      height: 35px;
      font-size: 12px;
      border-radius: 40px;
      background: #e9f0fa;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid white;
      text-transform: capitalize;
    }
    .moreDoc {
      font-weight: 600;
    }
  }
}

.addNewRoleModal {
  width: 780px;
  .description-addRole {
    margin-top: 16px;
  }
  h2 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  .Modal__closeButton {
    font-size: 24px;
  }
  h3 {
    color: #36383d;
    font-size: 16px;
    line-height: 24px;
  }
  .footer-btn {
    display: flex;
    justify-content: end;
    margin: 0 -24px;
    border-top: 1px solid #d7dff5;
    padding: 16px 24px 0;
    button {
      width: 119px;
      text-align: center;
      margin: 0 5px;
      .button-label-txt {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
      }
    }
  }
  .create-new-wrapper {
    margin: 12px -24px;
    background: #f0f4ff;
    padding: 12px 24px;
    .add-new-role {
      text-align: center;
      width: 346px;
      min-height: 180px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin: 8px 0;
      font-size: 14px;
      border: 1.5px dotted #ccd2e0;
      cursor: pointer;

      .plus-symbol {
        color: #8c97b8;
        font-size: 36px;
      }
    }
    .template-role {
      width: 346px;
      background: white;
      border-radius: 8px;
      margin: 8px 0;
      padding: 16px;
      display: flex;
      align-items: baseline;
      cursor: pointer;
      .templateRoles {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      input {
        margin-right: 12px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        margin: 12px 0 16px;
        color: #747e99;
        font-weight: 500;
      }
      .template-role--label {
        background: rgba(245, 69, 174, 0.1607843137);
        font-size: 12px;
        font-weight: 600;
        color: #f545ae;
        padding: 5px 12px;
        border-radius: 4px;
        display: inline-block;
        margin-top: 16px;
        &.not-all-access {
          background: #0051cc1f;
          color: #3c65d6;
        }
      }
    }
  }
  .roles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 0;
  }
}

.add-role-header {
  margin: 0 -24px 12px;
  padding: 0 24px 12px;
  border-bottom: 1px solid #d7dff5;
}
.permissions {
  margin: 16px 0;
  h3 {
    color: #36383d;
    font-size: 16px;
    line-height: 24px;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  .role--details {
    display: flex;
  }
  h4 {
    font-size: 14px;
  }
  .all-access {
    border: 1px solid #d7dff5;
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
      margin-top: 12px;
      line-height: 16px;
    }
  }
  .permission-row {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    color: #464c5c;
    padding: 8px 16px;
    .checkmark {
      display: inline-block !important;
      transform: rotate(45deg);
      height: 16px;
      width: 8px !important;
      border-bottom: 3px solid #3c65d6;
      border-right: 3px solid #3c65d6;
      padding-left: 0 !important;
    }
    input {
      transform: scale(1.3);
      cursor: pointer;
    }
    div {
      width: 20%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    :first-child {
      display: flex;
      width: 60%;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
      padding-left: 30px;
    }
  }

  .permission-header {
    margin-top: 8px;
    div {
      color: #464c5c;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .permission--header {
    &:hover,
    &.permission-active {
      background: #3c65d612;
      cursor: pointer;
    }

    .ri-account-circle-line,
    .module_icon {
      font-size: 24px;
      margin-right: 8px;
      width: 24px;
      color: #747e99;
    }
    .ri-arrow-up-s-fill,
    .dropdown-arrow {
      color: #747e99;
      width: 24px;
      font-size: 19px;
      padding: 0px 12px;
    }
    .ri-empty {
      width: 24px;
    }
    div {
      color: #2f323d;
      font-weight: 500;
    }
    :first-child {
      padding-left: 0px;
    }
  }
}
