.chat_details-container{
  &-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 24px 6px 24px;
    gap: 24px;
    border-bottom: 1px solid #D7DFF5;
    
    .chat-header{
      display: flex;
      align-items: center;
      gap: 24px;
      
      h3{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #1F1F1F;
    }
  }
}

  &-body{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 89vh;
   }
}