.lockin-modal {
  .lockin-modal-tabs {
    border-bottom: 1px solid #d7dff5;
    margin: 0 -24px;
    padding: 12px 0;
    
    span{
      padding: 12px 24px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #5C5C5C;
      &.active{
        color: #0061F5;
        border-bottom: 2px solid #0061F5;
      }
    }
  }

  .lockin-modal-header {
    border-bottom: 1px solid #d7dff5;
    margin: 0 -24px;
    padding: 0 24px 12px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #1F1F1F;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #5C5C5C;
    }
  }

  .lockin-modal-body {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .details {
      .details-row{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .details-panel{
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1;

          .icon{
            width: 48px;
            height: 48px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F5F5F5;

            i{
              font-size: 24px;
            }
          }
        }
      }

      .asset-type {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #1F1F1F;
      }

      h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #5C5C5C;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #5C5C5C;
        margin: 10px 0 5px 0;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #3D3D3D;
      }

      .input-group{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .input-custom{
        display: flex;
        flex-wrap: nowrap;
        gap: 8px;
       
        .input__group{
          height: 52px;
        }
      }
    }
  }

  .m-t-10{
    margin-top: 10px;
  }

  .lockin-modal-footer{
    display: flex;
    justify-content: flex-end;
    margin: 0 -24px;
    padding: 16px 24px 0px 24px;
    gap: 8px;
    border-top: 1px solid #d7dff5;
  }
}
.lockupError{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

.lockupErrorIcon{
  font-size: 16px;
  color: #DC1818;
  &__text{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #3D3D3D;
  }
}
}

.lockUpInputError{
  border-color: #ff4d4f;
  background: #EF43430F;
}

.btn-approved{
  background-color: #259351;
}
