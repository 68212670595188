@import "../../styles/helpers.sass";

.chat-container{
  padding: 16px 24px;
  &-main{
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .chat-header{
    padding: 16px;
    display: flex;
    justify-content: flex-end;

    .input{
      width: 336px;
    }
    .input__group {
      height: 44px;
    }
  }

  .chat-body{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .react-table-responsive-wrapper:has(.chats_list-container){
    height: 70vh;
  }

  .chats_list-container{
    tbody > tr{
      cursor: pointer;
      &:hover{
        background-color: $n6;
      }
    }

    .chat-name-container{
      .chat-name{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        color: #1F1F1F;
      }
      .client-id{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #5C5C5C;
        text-transform: uppercase;
      }
    }
    .chat-details-personal{
      .email{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #1F1F1F;
      }
      .phone{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #7A7A7A;
      }
    }
    .chat-details-message{
      .message{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #3D3D3D;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 400px;
      }
    .op-2{
      color: #5C5C5C;
    }
    }
    .chat-time-container{
      .chat-date{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        color: #1F1F1F;
      }
      .chat-time{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #5C5C5C;
        text-transform: uppercase;
      }
    }
    .chat-action{
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }
}