@import "../../styles//helpers.sass";


.super-admin-wrapper-sba {
  padding: 24px;
  // SBA7 Table css
  .minted-asset-admin {
    border: 1px solid #d7dff5;
    padding: 8px 0;
    border-radius: 8px;
  }
  .react-table-responsive-wrapper {
    .asset-name {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .memo-view {
      button {
        border: none;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 4px 12px;
        border-radius: 50px;
        background: #e27a133d;
        color: var(---color-black);
        width: 128px;
        text-transform: capitalize;
        &:disabled {
          cursor: not-allowed;
        }
        &.POOL {
    background: #8F14E13D;
}
      }
    }
    .asset-symbol {
      color: var(--color-text-60-light);
      font-size: var(--font-size-2);
      font-weight: 500;
      line-height: var(--line-height-2);
    }
    .asset-image {
      width: 40px;
      height: 40px;
      border: 1px solid $n6;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50px;
      }
    }
  }

  .circleNames {
    display: flex;
    position: relative;

    img {
      max-width: 20px;
    }
  }
  .circleName {
    position: relative;
    margin-left: -12px;

    &:first-child {
      margin: 0;
    }
    &:last-child {
      cursor: pointer;
    }
  }
  .circleNameS {
    width: 35px;
    height: 35px;
    font-size: 12px;
    border-radius: 40px;
    background: #e9f0fa;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
  }
}

.auction-date-flex-center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -5px;
  gap: 4px;
}

.counter-circle {
  margin-inline: 5px;
  width: 40px !important;
}

.super-admin-wrapper-sba {
  .react-table-responsive__body tr td button {
    width: 60px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    border: none;
    background: #49b876;
    color: #ffffff;
    margin: 6px 3px;
    min-height: unset;
    display: flex;
    justify-content: center;
    &.reject {
      background: #f5454512;
      color: #f55353;
    }
  }
  .btn-view {
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 12px;
    border-radius: 50px;
    background: rgba(60, 101, 214, 0.0588235294);
    color: var(--color-primary-light);
  }
  .ri-history-line {
    font-size: 20px;
    color: #8c97b8;
  }
  .auction-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    .history-action {
      position: relative;
      margin-right: 12px;
    }
    .history-view {
      position: absolute;
      width: 8px;
      height: 8px;
      right: -10px;
      background: #3c65d6;
      border-radius: 50px;
    }
    .APPROVED {
      color: #49b876;
    }
    .REJECTED {
      color: #f55353;
    }
    .INPROGRESS{
      color: gray;
    }
  }
  .circleDocs {
    display: flex;
    position: relative;

    img {
      max-width: 20px;
    }
  }
  .circleDoc {
    position: relative;
    margin-left: -12px;

    &:first-child {
      margin: 0;
    }
    &:last-child {
      cursor: pointer;
    }
  }
  .circleDocS,
  .moreDoc--list-img {
    width: 40px !important;
    height: 40px;
    border-radius: 40px;
    background: #3d3d3d0f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moreDoc-list {
    position: absolute;
    top: 100%;
    left: -100px;
    padding: 8px 12px 8px 12px;
    width: 280px;
    z-index: 9999;
    background: white;
    border: 1px solid #d7dff5;
    border-radius: 6px;

    .arrow {
      border-right: 3px solid #131010;
      position: absolute;
      left: 73%;
      top: 0;
    }

    .arrow:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e7e5e5;
      position: absolute;
      top: -12px;
      left: -9px;
    }
  }
  .moreDoc--list {
    display: flex;
    align-items: center;
    color: #747e99;
    span {
      width: 4px;
      height: 4px;
      background: #b0a4a4;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
  .moreDoc--list-img {
    margin-right: 8px;
  }
  .moreDoc-list-wrapper {
    max-height: 200px;
    overflow-y: scroll;
  }
  .startTime {
    color: #747e99;
    font-size: 12px;
  }
}

.approval-mint-detail-sba {
  width: 1098px;
  max-width: unset;
  padding: 0;
  .Modal__wrapper {
    padding: 8px;
  }
  h2 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
  }
  h3 {
    font-size: 16px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  .auction-img {
    margin: 24px -24px 0;
    padding: 24px;
    border-top: 1px solid #d7dff5;
    display: flex;
    align-items: center;
    gap: 12px;
    .mint-icon {
      width: 56px;
      height: 56px;
      border: 1px solid #e6e8ec;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 36px;
      height: 36px;
      border-radius: 50px;
    }
  }
  .auction-row {
    display: flex;
    padding: 12px 0;
  }
  .auction--row {
    width: 50%;
  }
  .loan-details {
    border-top: 1px solid #d7dff5;
    padding-top: 12px;
  }
  .supporting-doc {
    padding-top: 12px;
    border-top: 1px solid #d7dff5;
  }
  .support-doc {
    display: flex;
    margin-top: 16px;
    gap: 16px;
    flex-wrap: wrap;
    .file-comment {
      margin: 0 0 12px 12px;
    }
    .support-div {
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .btn-view button {
      border: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 4px 12px;
      border-radius: 50px;
      background: rgba(60, 101, 214, 0.0588235294);
      color: var(--color-primary-light);
    }
  }
  .support--doc {
    width: 50%;
    display: flex;
    color: #2f323d;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    gap: 16px;

    .doc-img {
      padding: 8px;
      width: 40px;
      border: 1px solid #d7dff5;
      border-radius: 50%;
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 20px;
    }

    .file-name {
      white-space: nowrap;
      width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_size {
      color: #5d657a;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .action-btns {
    display: flex;
    margin: 24px -18px 0;
    padding: 12px 24px 0;
    border-top: 1px solid #d7dff5;
    justify-content: end;
    gap: 12px;
    button {
      width: 120px;
      height: 48px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      &.reject {
        background: rgba(245, 83, 83, 0.06);
        color: #f55353;
      }
      &.approve {
        color: #fff;
        background: #33b87a;
      }
    }
  }
}
.reject-auction-detail {
  width: 733px;
  h3 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }
  p {
    color: #5d657a;
    font-size: 14px;
  }
  .reject--auction {
    margin: 24px -24px 0;
    padding: 24px;
    border-top: 1px solid #d7dff5;
    color: #5d657a;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #d7dff5;
    .doc-img {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .dot {
      width: 4px;
      height: 4px;
      background: #000;
      opacity: 0.24;
      border-radius: 50px;
    }
    .support-action {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    button {
      border: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 4px 12px;
      border-radius: 4px;
      &:first-child {
        color: #777e90;
      }
      &:nth-child(2) {
        background: rgba(60, 101, 214, 0.06);
        color: #3c65d6;
      }
    }
    .support-doc {
      border-bottom: 1px solid #d7dff5;
      &:last-child {
        border-bottom: none;
      }
    }
    .cooment-add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      i {
        cursor: pointer;
      }
    }
    .support--doc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      &_name {
        color: #2f323d;
      }
    }
    .input {
      margin: 4px 0 12px;
    }
    .input__group {
      height: 72px;
      border-radius: 4px;
      .input__text-field {
        border: 1px solid #d7dff5;
        background: transparent;
      }
    }
  }
  .action-btns {
    display: flex;
    padding: 12px 0px 0;

    justify-content: end;
    gap: 12px;
    button {
      width: 120px;
      height: 48px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      &.reject {
        color: white;
        background: #f55353;
      }
      &.approve {
        color: #464c5c;
        background: #ebeef5;
      }
    }
  }
}

.auction-history {
  width: 768px;
  .history-header {
    border-bottom: 1px solid #d7dff5;
    margin: 0 -24px;
    padding: 0 24px 12px;
  }
  .support--doc {
    display: flex;
    align-items: center;
    padding: 16px 0;
    max-width: 100%;
    gap: 12px;
    &_name {
      color: #2f323d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
  }
  .doc-img {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .doc--img {
    background: rgba(61, 61, 61, 0.06);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    img {
      max-width: 21px;
    }
  }
  .dot {
    width: 4px;
    height: 4px;
    background: #000;
    opacity: 0.24;
    border-radius: 50px;
  }
  .support-action {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 12px;
    border-radius: 50px;
    background: rgba(60, 101, 214, 0.06);
    color: #3c65d6;
  }
  h2 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  .history-log {
    padding: 24px 0 0;
    max-height: 650px;
    overflow: auto;
    .history-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 400px;
    }
    .history-comment {
      .history-icon {
        background: #f553531f;
        i {
          color: #d32222;
        }
      }
      .file-comment {
        b {
          color: #2f323d;
        }
      }
      .history-line {
        height: 160px;
      }
    }
    .history--log:last-child {
      .history-line {
        display: none;
      }
    }
    .history--log:last-child .history-row:nth-child(2) .history-data {
      border-left: none;
    }
    .action {
      color: #2f323d;
      font-weight: 600;
      line-height: 20px;
      &.new-doc {
        margin-top: 8px;
      }
    }
    .history-row {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      color: #7c8398;
    }
    .history-data {
      margin-left: 19px;
      padding-left: 30px;
      border-left: 1px solid #d7dff5;
      padding-bottom: 12px;
      min-height: 42px;
    }
    .history-icon {
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: rgba(139, 69, 245, 0.12);
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
    }
    i {
      color: #8b45f5;
      font-size: 17px;
    }
    .ri-chat-2-line {
      color: black;
    }
    .history-date {
      color: #747e99;
      font-size: 12px;
      font-weight: 500;
    }
    .doc--comment {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 16px;
    }
    .history-line {
      width: 1px;
      background: #d7dff5;
      height: 60px;
    }
    .history-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 12px;
    }
    .history-right {
      width: 100%;
      .name {
        text-transform: capitalize;
      }
    }
    .history--log.approved {
      i {
        color: #389f75;
      }
      .history-icon {
        border: 1px solid rgba(0, 0, 0, 0.12);

        background: rgba(51, 184, 122, 0.12);
      }
    }
  }
}

.admin-body:has(.minted-asset-admin) {
  max-height: unset;
}
