@import "../../../../styles/helpers.sass";

.no-chat-container{
  display: flex;
  justify-content: center;
  height: 50vh;
  flex-direction: column;
  align-items: center;

  i{
    font-size: 44px;
  }
  p{
    font-size: 16px;

  }
}
