.AppManagement {
  padding: 16px;
  background: #f0f4ff;
  min-height: calc(100vh - 64px);
  &--wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &--container {
    background-color: #fff;
    width: 49%;
    border-radius: 6px;
    padding: 10px;
    min-height: 200px;
    @media screen and (max-width:768px) {
     width: 100%;
    }
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(51, 87, 184, 0.12);
    padding-bottom: 20px;
  }
  &--left {
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &--btn {
    padding-inline: 20px;
    min-height: 40px;
  }
  &--body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
      font-size: 14px;
      font-weight: 500;
      width: 90%;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
