.revenue {
  background: #f4f4f6;
  padding: 24px;
  &__calender-btn {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  }
  .calender-btn {
    border-radius: 8px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 12px;
    color: #5d657a;
    .ri-arrow-down-s-line {
      display: none;
    }
  }
  .revenue-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .revenue-chart {
      width: 49%;
      background: white;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 20px;
      &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__label {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #8c97b8;
      }
      &__tag {
        background: #363a450f;
        font-size: 14px;
        color: #5d657a;
        padding: 4px 8px;
      }
      h3 {
        color: #2f323d;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .tabs {
      display: flex;
      border-bottom: 1px solid #d7dff5;
      .tab {
        padding: 12px 20px;
        cursor: pointer;

        &.active {
          color: #3c65d6;
          border-bottom: 1px solid #3c65d6;
        }
      }
    }
    .tab-content {
      margin-top: 20px;
    }
  }
  .revenue--summary {
    border: 1px solid #d7dff5;
    border-radius: 8px;
    font-size: 14px;
    width: 100%;
    display: flex;
    padding: 12px;
    background: white;
    justify-content: space-between;
    &-icon {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-left {
      display: flex;
    }
    &-profit {
      color: #33b87a;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
      line-height: 20px;
    }

    &-label {
      color: #747e99;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 8px;
    }
    .icon-dollar {
      font-size: 40px;
      i {
        color: #458bf5;
      }
    }
    .icon-coin {
      background: #8b45f5;
      i {
        color: white;
        font-size: 24px;
      }
    }

    .icon-auction {
      background: #33b87a;
      i {
        color: white;
        font-size: 24px;
      }
    }
    .revenue-total {
      margin-left: 8px;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #17191f;
    }
  }
  .revenue-summary {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }
  .dount-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dount--chart {
      width: 48%;
    }
    .donut-table {
      border: 1px solid #d7dff5;
      border-radius: 12px;
    }
  }
}

.revenue-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.power_BI__parent-container {
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  height: calc(100vh - 62px); /* Full viewport height */
  width: 100%; /* Full width */
}

