.filter-container {
  position: relative;
  height: 100%;
  display: flex;
  .input__text-field {
    background-color: white;
    flex-direction: row-reverse;
    height: 48px;
  }
  .filter-count {
    position: absolute;
    right: 0;
    top: -5px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    color: white;
    background: #0051cc;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-label-txt {
    color: #464c5c;
    font-size: 14px;
    margin-left: 8px;
  }
  &-header {
    button {
      border: 1px solid #b3a8a8;
      min-height: unset;
      padding: 2px 6px;
    }
  }
  .button {
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    height: 100% !important;
  }

  .filter-container-body-wrapper {
    background-color: #ffffff;
    position: absolute;
    top: 50px;
    width: 300px;
    height: auto;
    z-index: 999;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: all 0.25s ease-in;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .filter-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-radius: 4px 4px 0px 0px;
    gap: 16px;
    .button{
      min-height: unset !important;
      padding: 2px 6px !important;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: black;
    }
  }

  .filter-container-body {
    display: flex;
    flex-direction: column;

    .filter-container-main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px 12px 16px;
      border-top: 1px solid #d7dff5;
      cursor: pointer;
      gap: 8px;
      color: black;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 12px;
        }
      }

      span {
        color: #747e99;
        font-size: 20px;
      }
    }
  }

  .filter-container-options {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    background: #f4f5f6;
    gap: 8px;
    transition: all 0.25s ease-in;
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 330px;
    overflow-y: scroll;
    word-break: break-word;
  }

  .Checkbox_input__QX96x:checked
    + .Checkbox_inner__HHP-u
    .Checkbox_tick__avD9P {
    border: 1px solid #d7dff5;
    background: #49b875;
  }

  .Checkbox_text__MPGo0 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #6c707a;
    align-self: center;
  }
}

.minted-asset-admin {
  .input__text-field {
    background: transparent;
  }
  .filter-container {
    .button {
      // cursor: not-allowed;
      font-weight: 500;
      font-size: 14px;
      border-radius: 8px;
      height: 100% !important;
      min-height: 46px;
      width: 94px;
      display: flex;
      justify-content: center;
      gap: 8px;
      padding: 0;
      border: 1px solid var(--color-input-border-light);
      border-radius: 8px;
      .button-label-txt,
      i {
        color: var(--color-text-40-light);
      }
      .ri-filter-3-line {
        font-size: 18px;
      }
    }
  }
}
.no-users-found {
  font-size: 14px;
  font-weight: 600;
  color: black;
  padding: 10px 8px;
  text-align: center;
}
