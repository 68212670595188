@import "../../styles//helpers.sass";


.super-admin-wrapper-dvp {
  padding: 24px;
  // SBA7 Table css
  .filter__container-search{
    max-width: 336px;
  }
  .react-table-responsive__body tr td{
    color: #1F1F1F;
    padding-right: 18px;
   .userEmail{
     overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 264px;
   }
  }
  .new-dvp {
    display: flex;
    align-items: center;
    gap: 8px;
    &_action {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #CA161614;
    border: 1px solid #CA1616;
    border-radius: 4px;
    color: #CA1616;
    font-size: 16px;
    &.accept{
      border: 1px solid #259351;
      background: none;
      color: #259351;
    }
}
}
  .minted-asset-admin {
    border: 1px solid #d7dff5;
    padding: 8px 0;
    border-radius: 8px;
  }
  .react-table-responsive-wrapper {
    .asset-name {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .memo-view {
      button {
        border: none;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 4px 12px;
        border-radius: 50px;
        background: #e27a133d;
        color: var(---color-black);
        width: 128px;
        text-transform: capitalize;
        &:disabled {
          cursor: not-allowed;
        }
        &.POOL {
    background: #8F14E13D;
}
      }
    }
    .asset-symbol {
      color: var(--color-text-60-light);
      font-size: var(--font-size-2);
      font-weight: 500;
      line-height: var(--line-height-2);
    }
    .asset-image {
      width: 40px;
      height: 40px;
      border: 1px solid $n6;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50px;
      }
    }
  }

  .agreement-clm{
    color:var(--color-primary-light-blue);
    gap:4px;
    font-size: 14px;


}
.status{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    Padding:8px, 12px, 12px, 12px;
    width: 72px;
    height: 32px;
    border: 1px solid var(--color-primary-light-blue);;
    border-radius: 4px;
    gap:4px;
   
}
.approved-btn{
    background-color: var(--color-green-light);
    color: var(--color-bg-100-light);
    border-radius: 24px;
    border:none;
    width:91px;
    height: 24px;
    cursor: not-allowed;
}
.reject-btn{
    border-radius: 24px;
    border:none;
    width:91px;
    height: 24px;
    background: #F553531F;
   color: #F55353;
 }
}

.auction-date-flex-center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -5px;
  gap: 4px;
}

.counter-circle {
  margin-inline: 5px;
  width: 40px !important;
}

.super-admin-wrapper-dvp {
  .btn-view {
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 12px;
    border-radius: 50px;
    background: rgba(60, 101, 214, 0.0588235294);
    color: var(--color-primary-light);
  }
  .ri-history-line {
    font-size: 20px;
    color: #8c97b8;
  }
  .auction-actions {
    display: flex;
    align-items: center;
    gap: 8px;
        padding-right: 12px;

    .history-action {
      position: relative;
      margin-right: 12px;
    }
    .history-view {
      position: absolute;
      width: 8px;
      height: 8px;
      right: -10px;
      background: #3c65d6;
      border-radius: 50px;
    }
    .APPROVED {
      color: #49b876;
    }
    .REJECTED {
      color: #f55353;
    }
    .INPROGRESS{
      color: gray;
    }
  }
  .circleDocs {
    display: flex;
    position: relative;

    img {
      max-width: 20px;
    }
  }
  .circleDoc {
    position: relative;
    margin-left: -12px;

    &:first-child {
      margin: 0;
    }
    &:last-child {
      cursor: pointer;
    }
  }
  .circleDocS,
  .moreDoc--list-img {
    width: 40px !important;
    height: 40px;
    border-radius: 40px;
    background: #3d3d3d0f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moreDoc-list {
    position: absolute;
    top: 100%;
    left: -100px;
    padding: 8px 12px 8px 12px;
    width: 280px;
    z-index: 9999;
    background: white;
    border: 1px solid #d7dff5;
    border-radius: 6px;

    .arrow {
      border-right: 3px solid #131010;
      position: absolute;
      left: 73%;
      top: 0;
    }

    .arrow:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e7e5e5;
      position: absolute;
      top: -12px;
      left: -9px;
    }
  }
  .moreDoc--list {
    display: flex;
    align-items: center;
    color: #747e99;
    span {
      width: 4px;
      height: 4px;
      background: #b0a4a4;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
  .moreDoc--list-img {
    margin-right: 8px;
  }
  .moreDoc-list-wrapper {
    max-height: 200px;
    overflow-y: scroll;
  }
  .startTime {
    color: #747e99;
    font-size: 12px;
  }
}



.approve-dvp-detail {
  max-width: 560px;
   width: 100%;
}

.reject-dvp-detail {
  max-width: 480px;
    width: 100%;
}
.reject-dvp-detail,.approve-dvp-detail {
  .modal-title {
    font-weight: 600;
    color: #36383D;
    margin-top: 8px;
}
  h3 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }
  p {
    color: #5d657a;
    font-size: 14px;
  }
  .reject--auction {
    margin: 12px -24px 0;
    padding: 12px 24px;
    border-top: 1px solid #d7dff5;
    color: $n67;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #d7dff5;
    .request-limit{
          margin-bottom: 12px;
      span{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color:  var(--color-header-label-dark);
      }
    }
    .doc-img {
      display: flex;
      align-items: center;
      gap: 12px;
      img{
        max-width: 20px;
      }
    }
    .dot {
      width: 4px;
      height: 4px;
      background: #000;
      opacity: 0.24;
      border-radius: 50px;
    }
    .support-action {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    button {
      border: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 4px 12px;
      border-radius: 4px;
      color: #0061F5;
      background: #0061F50F;

    }
    .support-doc {
      border: 1px solid #0000001F;
      border-radius: 4px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    .support--doc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      &_name {
        color: #2f323d;
      }
    }
    .input {
      margin: 4px 0 18px;
    }
    .input__group {
      height: 48px;
      border-radius: 4px;
      .input__text-field {
        border: 1px solid #d7dff5;
       background:  #00000008;

        
      }
    }
    &.reject--dvp{
      padding: 18px 24px;
      .input{
        margin: 0;
      }
    }
  }
  .action-btns {
    display: flex;
    padding: 18px 0px 0;

    justify-content: end;
    gap: 12px;
    button {
      width: 120px;
      height: 48px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      &.reject {
        color: white;
        background: #f55353;
      }
      &.approve {
        color: white;
        background: var(--color-green);
      }
      &.cancel {
        color: #464c5c;
        background: #ebeef5;
      }
    }
  }
}

.reject-dvp-detail{
  .Modal__wrapper{
    padding: 0;
  }
  .reject--auction{
    border-top: none;
    margin: 0;
  }
  .action-btns{
    padding: 18px 24px;
  }
}

.view-document {
    max-width: 80%;
        width: 100%;
    &--iframe{
      height: 80vh;
    width: 100%;
    border:none
    }
}
