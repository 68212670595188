.progress-container {
    width: 100%;
    font-family: Arial, sans-serif;
  }
  
  .labels {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .progress-bar-wrapper {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.12); 
    border-radius: 8px;
    overflow: hidden;
  }
  
  .min-raise-indicator {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px; 
    background-color: black; 
    z-index: 2; 
    left: 50%; 
    transform: translateX(-50%); 
  }
  
  .progress-bar {
    height: 100%;
    border-radius: 8px;
    transition: width 0.3s ease, background-color 0.3s ease;
  }
  
  .progress-bar-red {
    background-color: #ca1616; 
    min-width:0px !important
  }
  
  .progress-bar-green {
    background-color: #29a359;
  }
  
  .progress-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.12); 
    border-radius: 8px;
    position: absolute;
    top: 0;
  }
  
  .amount-labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 8px;
  }
  