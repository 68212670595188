.country-label {
  width: 200px;
}

.option {
  display: flex;
  align-items: center;
  gap: 4px;
  &__flag {
    width: 30px;
    height: 20px;
  }
  &__label {
    font-size: 16px;
  }
}

.css-1s2u09g-control {
  background-color: transparent !important;
  border-style: none !important;
}

.css-1pahdxg-control {
  cursor: pointer;
  border-style: unset !important;
  border-color: unset !important;
  box-shadow: unset !important;
  background: transparent !important;
  &:hover {
    border-style: unset !important;
  }
}

.css-1pahdxg-control {
  border-color: none !important;
}

.css-1pahdxg-control:hover {
  // border-color: none !important;
}

.css-tlfecz-indicatorContainer {
  // display: none !important;
}
