.InviteUserModal {
  width: 584px;

  .inviteUser {
    &__phone-number {
      border: 1px solid var(--color-input-border-light);
      border-radius: 4px;
      height: 52px;
      display: flex;
      align-items: center;
      background: var(--color-input-background);

      &.country-label {
        max-width: 200px;
      }
      
    }
    &__phone-header {
      margin-top: 12px;
      font-size: var(--font-size-3);
      line-height: var(--line-height-3);
      color: var(--color-text-70-light);
      text-transform: capitalize;
      font-weight: 500;
    }
    &__phone-input {
      padding: 11px 8px;
      background: transparent;
      border: unset;
      letter-spacing: 2px;
      font-size: 16px;
      width: 100%;
      //-webkit-box-shadow: 0 0 0 1000px #282a2e inset !important ;
      -webkit-text-fill-color: #000 !important;
      
    }
    &__phone-input:focus{
      outline: unset !important;
    }
  }
  .invite-name {
    display: flex;
    gap: 24px;
  }
  .input {
    margin-top: 12px;
  }
  h3 {
    color: #2f323d;
    font-size: 20px;
    line-height: 28px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #5d657a;
  }
  .invite-name {
    display: flex;
    gap: 24px;
    margin: 16px -24px 0;
    padding: 0 24px;
    border-top: 1px solid #d7dff5;
  }
  .footer-btn {
    gap: 12px;
    border-top: 1px solid #d7dff5;
    margin: 16px -24px 0;
    padding: 16px 24px 0;
    display: flex;
    justify-content: end;
    button {
      width: 120px;
      .button-label-txt {
        width: 100%;
      }
    }
  }
}

.css-13cymwt-control {
  border-width: none !important;
  border-style: none !important;
  background: transparent !important;
}

.css-t3ipsp-control{
  border: unset !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  
}
