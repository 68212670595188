.button {
    display: flex;
    align-items: center;
    border-radius: calc(var(--border-radius) * 0.25);
    background: var(--color-transparent);
    outline: none;
    border: none;
    white-space: nowrap;
    gap: calc(var(--margin) * 0.5);
    width: fit-content;
    transition: color 0.5ms ease-in-out;
    min-height: 48px;
    cursor: pointer;
  
    &__iconFront {
      flex-direction: row-reverse;
    }
    &__filled {
      &--primary {
        color: var(--color-bg-100-light);
        background-color: var(--color-primary-light);
        justify-content: center;
        padding: 8px 16px;
        min-width: 96px;
      }
      &--secondary {
        background: var(--color-white-border);
        color: var(--color-bg-80-dark);
        padding: 8px 16px;
        min-width: 96px;
        justify-content: center;
      }
      &--danger {
        background: var(--color-error);
        color: var(--color-bg-90-light);
      }
    }
    &__outline {
      border: 1px solid var(--color-primary-dark);
      color: var(--color-primary-dark);
    }
    &__ghost {
      &--primary {
        background: rgba(60, 101, 214, 0.12);
        border-radius: 24px;
        color: var(--color-primary-light);
      }
      &--danger {
        background: rgba(245, 69, 69, 0.12);
        border-radius: 24px;
        color: #f54545;
      }
    }
    &__large {
      padding: var(--padding) calc(var(--padding) * 1.5);
      font-size: var(--font-size-4);
      border-radius: calc(var(--border-radius) * 0.5);
    }
    &__block {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__small {
      padding: calc(var(--padding) * 0.5) calc(var(--padding) * 0.75);
      font-size: var(--font-size-2);
      line-height: var(--line-height-2);
    }
    &--disabled {
      opacity: 0.7;
      cursor: not-allowed;
      &:hover {
        box-shadow: unset;
      }
    }
  }
  .button-label-txt {
    font-size: 12px;
    font-weight: 500;
  }
  