.account-wrapper {
  margin: 24px 24px 8px;
  border: 1px solid #d7dff5;

  tbody tr:hover{
    cursor: pointer;
    transition: 0.25 ease-in;
    background-color: lightgrey;
  }


  .account-conpany-name {
    display: flex;
    align-items: center;
    flex-flow: row;
    gap: 8px;

    .name-initials{
      width: 40px; 
      height: 40px;
      border-radius: 50%;
      background-color: #D7DFF5; 
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px; 
    }

    .company-details-general {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #464c5c;
        text-transform: capitalize;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 16ch;
      }

      .account-location {
        display: flex;
        p{
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          color: #747e99;
          text-transform: capitalize;
          &:nth-child(1):not(:empty)::after{
            content: ", ";
          }
          &:nth-child(2):not(:empty){
            margin-left: 2px;
          }
        }
        
       
      }
    }
  }

  .account-details-personal {
    width: fit-content;
    .email {
      font-size: 14px;
      font-weight: 500;
      color: #464c5c;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 30ch;
    }

    .phone {
      font-size: 10px;
      font-weight: 500;
      color: #747e99;
      text-transform: lowercase;
    }
  }

  .account-type {
    display: flex;
    gap: 8px;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #464c5c;
    .ri {
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 40px;
    }
    .ri-briefcase-fill {
      background-color: rgba(139, 69, 245, 0.12);
      color: #8b45f5;
    }
    .ri-user-3-fill {
      background-color: rgba(245, 175, 69, 0.12);
      color: #f5af45;
    }
  }

  .account-status {
    display: flex;
    gap: 2px;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #464c5c;

    .ri {
      width: 20px;
      height: 20px;
    }

    .ri-checkbox-circle-fill {
      color: #33b87a;
    }

    .ri-close-circle-fill {
      color: #f55353;
    }

    .ri-time-fill {
      color: #f5af45;
    }
  }

  .account-actions {
    display: flex;
    gap: 16px;
    align-items: center;
    button,
    .ri {
      cursor: pointer;
    }

    .circle-loader{
      width: auto;
    }
    .ri {
      font-size: 16px;
      color: #747e99;
    }

    button {
      border-radius: 32px;
      padding: 4px 12px;
      font-size: 12px;
      color: rgba(60, 101, 214, 1);
      background-color: rgba(60, 101, 214, 0.06);
      border: none;
      white-space: nowrap;
    }
  }

  .session-status {
    display: flex;
    height: 100%;
    align-items: center;
    text-transform: capitalize;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.simplici-session-loader {
  min-height: 700px;
  display: flex;
  align-items: center;
}
