.access-denied-container {
    gap: 24px;
  .header-logo {
    position: fixed;
    top: 24px;
    left: 47px;
  }
  .access-denied-details {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .access-denied-image{
        margin-bottom: 24px;
        width: 150px;
    }

    p {
      text-align: center;
    }

    .text-url {
      font-size: 1.5vw;
      font-weight: 600;
      margin-bottom: 5px;
      gap: 15px;
    }

    .access-invalid {
      font-size: 1vw;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .contact-email {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      background-color:#f6f8fd; 
      border-radius: 32px;
      padding: 8px 16px;
      gap: 8px;
    }

    .email-color {
      color: #3c65d6;
    }
  }
}
.access-denied-container {
    gap: 24px;
  .header-logo {
    position: fixed;
    top: 24px;
    left: 47px;
  }
  .access-denied-details {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .access-denied-image{
        margin-bottom: 24px;
        width: 150px;
    }

    p {
      text-align: center;
    }

    .text-url {
      font-size: 1.5vw;
      font-weight: 600;
      margin-bottom: 5px;
      gap: 15px;
    }

    .access-invalid {
      font-size: 1vw;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .contact-email {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      background-color:#f6f8fd; 
      border-radius: 32px;
      padding: 8px 16px;
      gap: 8px;
    }

    .email-color {
      color: #3c65d6;
    }
    .accessdenied-logout{
      font-weight: 500;
      border-color: transparent;
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
      border-radius: 4px;
      padding: 16px 24px;
      height: 52px;
      width: 120px;
    }
  }
}
