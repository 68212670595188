.react-table-responsive-container {
  height: 100%;
}
.table-responsive-actions {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: var(--font-size-5);
    color: var(--color-text-90-light);
    font-weight: 500;
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: calc(var(--base-margin) * 1.25);
    gap: 8px;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
}

.react-table-responsive {
  border-collapse: collapse;
  width: 100%;
  &__head {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
    tr {
      th {
        padding: 16px 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #747e99;
        //  color: var(--color-text-60-light);
        font-size: var(--font-size-3);
        font-weight: 600;
        // max-width: 128px;
        max-width: 125px;
        // padding-top: 4px;
        // padding-bottom: 4px;
        text-align: left;
        &:first-child {
          padding-left: 12px;
        }
        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }

  &__body {
    tr {
      background-color: var(--color-bg-100-light);
      border-bottom: 1px solid var(--color-input-border-light);

      &.row__hover:hover {
        background-color: var(--color-hover-light-100);
        transition: 0.3s ease-in all;
        cursor: pointer;
      }
      // td:before {
      //   content: "&nbsp;";
      //   visibility: hidden;
      // }
      td {
        padding: 0;
        color: var(--color-text-80-light);
        position: relative;
        border-right: none;
        font-size: var(--font-size-3);
        font-weight: 500;
        height: 56px;
        &:first-child {
          padding-left: 12px;
        }
        > div {
          // position: absolute;
          // left: 16px;
          // right: 5px;
          // top: 50%;
          // transform: translateY(-50%);
          // white-space: nowrap;
          // // overflow: hidden;
          // text-overflow: ellipsis;
        }
        &.btn__text {
          > div {
            display: flex;
            align-items: center;
            width: 260px;
          }
          .text_cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .btn__Onhover {
          display: none;
        }
      }
      &:hover {
        .btn__Onhover {
          display: block;
        }
        .btn__text {
          > div {
            top: 6px;
          }
        }
      }
    }
  }
  &__sort-icon {
    margin-left: calc(var(--base-margin) * 0.5);
    transition: transform 0.5s, border-color 0.5s, -webkit-transform 0.5s;
  }
}
.loader-container {
  width: 100%;
  min-height: 200px;
  height: 100%;
  // display: flex;
  //justify-content: center;
  //align-items: center;
}

.skeleton-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--base-margin);
  &__message {
    text-align: center;
    font-size: var(--font-size-4);
    color: var(--color-text-90-light);
  }
}
.empty-table-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--base-margin);
  height: calc(100vh - 602px);
  //height: calc(100% - 62px);
  &__message {
    text-align: center;
    font-size: var(--font-size-4);
    color: var(--color-text-80-light);
    font-weight: 500;
  }
  &__sub-message {
    text-align: center;
    font-size: var(--font-size-2);
    color: var(--color-text-60-light);
    margin-bottom: 24px;
  }
}

.react-table-responsive-wrapper {
  overflow-x: auto;
  width: 100%;
  min-height: 400px;
}
.react-table-responsive-wrapper.react-table-responsive-fund-details-wrapper {
  height: calc(100vh - 252px);
  @media screen and (max-width: 1000px) {
    height: calc(100vh - 296px);
  }
  @media screen and (max-width: 600px) {
    height: calc(100vh - 330px);
  }
}

.react-table-responsive-sessions-wrapper {
  height: calc(100vh - 222px);
}

.react-table-responsive-pipeLine-wrapper {
  height: calc(100vh - 222px);
}

.react-table-responsive-wrapper::-webkit-scrollbar {
  max-height: 10px;
  width: 8px;
  background: transparent;
}

.react-table-responsive-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
}

.react-table-responsive-wrapper:hover::-webkit-scrollbar-thumb {
  background: #51545c;
}

.table-responsive-actions__btns {
  display: flex;
  justify-content: flex-end;
  position: relative;
  i {
    font-size: 20px;
  }
  input::placeholder {
    color: var(--color-text-40-light);
  }
  .input__text-field {
    background-color: transparent;
    flex-direction: row-reverse;
  }
  .ri-search-line {
    color: var(--color-text-40-light);
  }
  .input {
    width: 300px;

    .input__value-container {
      .input__group {
        height: 46px;
      }
    }
  }
  button {
    min-height: 46px;
    width: 94px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 8px;
    padding: 0;
    border: 1px solid var(--color-input-border-light);
    border-radius: 8px;
    color: var(--color-text-40-light);
    &:hover {
      border: 1px solid var(--color-input-border-light);
      color: var(--color-text-40-light);
    }
  }
  .button-label-txt {
    font-size: 14px;
    font-weight: 500 !important;
  }
}
.table__head-data--label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  span {
    width: min-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: 2px;
  }
  &__true {
    margin-left: 2px;
    transform: rotate(180deg);
    transition: transform 0.1s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  &__false {
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s ease-out;
    font-size: 20px;
  }
}

.react-table-responsive__body tr {
  background-color: var(--color-bg-100-light);
}

.filter-btn-wrapper {
  height: 48px;
  display: flex;
}

.filters-indicator {
  width: 20px;
  height: 20px;
  border-radius: 25px;
  background: var(--color-primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-bg-100-light);
}
.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 0 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl-table thead th {
  color: #464c5c;
}

.fl-table tr {
  background-color: var(--color-bg-100-light);
  border-bottom: 1px solid var(--color-gray-dark-100);
}

.fl-table tr:last-child {
  border-bottom: none;
}

.email__contact {
  overflow: hidden;
  text-overflow: ellipsis;
}

.check-box {
  width: 16px;
  height: 16px;
  margin: auto 6px;
  cursor: pointer;
  accent-color: var(--color-primary-light);
}

.text-elipsis {
  max-width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.text-elipsis-pipeline {
  max-width: 400px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.react-table-responsive-agreement-document-modal-sessions-wrapper {
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}
