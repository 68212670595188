// header
p,
h1,
h2,
h2,
h4,
h5,
h6 {
  margin: unset;
  text-align: unset;
  line-height: unset;
}

.tab-content {
  padding: 0;
}

* {
  text-align: unset;
}

button {
  text-align: center;
}

div {
  box-sizing: border-box;
}

// scss for headers...
.header {
  height: 62px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-100-light);
  padding: var(--zero-padding) calc(var(--base-padding) * 1.5);
  border-bottom: 1px solid rgba(51, 87, 184, 0.12);
  &__logo {
    width: 288px;
    border-right: 1px solid var(--color-input-border-light);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--zero-padding) calc(var(--base-padding) * 1.5);
  }
  &__img {
    width: 144px;
  }
  &__name {
    font-size: var(--font-size-4);
    font-weight: 600;
    color: var(--color-text-100-light);
  }
  &__right {
    display: flex;
    align-items: center;
    gap: var(--base-margin);
  }
  &__profile {
    font-size: var(--font-size-5);
    font-weight: 700;
    cursor: pointer;
  }
}

// Main.scss
.layout-container {
  max-height: 100vh;
  overflow: hidden;
  // background-color: #f4f4f6;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  // height: 100vh;
  // overflow: hidden;
  .admin-body {
    max-height: calc(100vh - 42px);
    overflow: scroll;
    height: 100%;
  }
}
