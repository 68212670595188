.signin-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	display: grid;
	place-items: center;
	inset: 0;
	&__inner {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 488px;
		width: 100%;
		&__form {
			background: #fff;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			gap: 24px;
			justify-content: center;
			padding: 48px 32px;
		
		
			&__title {
				color: #17191f;
				font-size: 24px;
				font-weight: 600;
				line-height: 32px;
			}

			&__subtitle {
				color: #747e99;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
			}
			&__googleSignIn{
				Width:448px;
				background: #fff;
				Height:48px;
				border-radius: 8px;
				color: #464C5C;
				border: 2px solid rgba(0, 0, 0, 0.12);
				padding: 12px 24px;
				display: flex;
				gap: 8px;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			&__error{
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 14px;
				line-height: 20px;
				font-weight: 400;

				& > i{
					color: red;
					font-size: 17px;
				}
			}
			&__divider{
				display: flex;
				align-items: center;
				gap: 8px;
				&__line{
					height: 1px;
					width: 206px;
					background: #D9D9D9;
				}
				&__text{
					font-size: 14px;
					font-weight: 500;
					line-height: 24px;
					color: #5D657A;
				}
			}
		}
		
	}
}
.signin{
	&__header{
		padding: 16px 24px 16px 24px
	}
}
.login_Container{
	display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-header{
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.error-body{
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.disabled{
	cursor: not-allowed;
	background-color: #747e99;
}