.activity-log {
  padding: 24px;

  &__calender-btn {
    display: flex;

    margin-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #D7DFF5;
    padding-bottom: 10px;
  }
  &_header{
    flex: 0 0 auto;
    font-size: 14px;
    font-family: Poppins;
    color: #17191F;
  }
  &_wrapper{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
  }

  .activity-skelton {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &.load-more {
      margin-top: 24px;
    }
  }
  .activity--skelton {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }
  .skeleton_logo.skeleton-loader-light {
    width: 30px;
    height: 30px;
  }
  .skeleton_Header {
    width: 350px;
    display: flex;
    flex-direction: column-reverse;
  }
  .calender-btn {
    color: #6c7489;
    background: hsl(224deg 65% 54% / 6%);
    border: none;
    flex-direction: row-reverse;
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
    .ri-arrow-down-s-line {
      display: none;
    }
  }
  .filter-container {
    color: #6c7489;
    background: hsla(224, 65%, 54%, 0.06);
    border: none;
    flex-direction: row-reverse;
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
  }
  .activity--log:last-child {
    .activity-line {
      display: none;
    }
  }
  .action {
    color: #2f323d;
    font-weight: 600;
    line-height: 20px;
  }
  .activity-row {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: #2f323d;
    word-break: break-word;
    min-height: 80px;
  }

  .activity-icon {
    position: relative;
    background: #e89c2c12;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 1; /* Place the icon above the line */
     
    i {
      color: #f5af45;
      font-size: 18.5px;
    }

    &.invited {
      background: #f545ae1f;
      i {
        color: #f545ae;
      }
    }
    &.joined,
    &.created {
      background: #33b87a1f;
      i {
        color: #33b87a;
      }
    }
    &.deleted,
    &.removed {
      background: #f553531f;
      i {
        color: #f55353;
      }
    }
    &.edited {
      background: #3c65d61f;
      i {
        color: #3c65d6;
      }
    }

    &.updated {
      background: #3c65d61f;

      i {
        color: #AF52DE;
      }
    }
  }

  .activity-date {
    color: #747e99;
    font-size: 12px;
    font-weight: 500;
  }

  .activity-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    background-color: white;
  }

  .activity-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #d7dff5;
    z-index: -1;
  }

  .activity-item-gap{
    display: flex;
    flex-direction: column;

  }

  .custom-table {
    width: auto;
    border-spacing: 0; // Ensures no extra spacing between cells
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.12); // Ensures a clear outer border
    border-radius: 8px;
    margin: 8px 0;
    overflow: hidden;

    th,
    td {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 12px 15px;
      border: 1px solid rgba(0, 0, 0, 0.12); // Adds a border between cells
    }

    th {
      min-width: 168px;
      background-color: #f5f5f5;
      color: #3D3D3D;
    }

    td {
      color: #1F1F1F;
    }

    tbody tr {
      &:first-child td {
        border-top: none; // Prevents extra border above the first row
      }
    }
  }

  .p-8{
    padding: 8px 0;
  }
}