.user_modal {
  width: 1283px;
  max-width: 1300px;

  p{
    margin: 0;
  }

  tr td:nth-child(3)::first-letter{
    text-transform: capitalize;
  }

  .Modal__wrapper {
    padding: 0px;
    border-radius: 16px;
  }

  .user_modal_title {
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid #d7dff5;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #2f323d;
    }
  }

  .user-modal_body {
    padding: 24px;

    .account-conpany-name {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: 8px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        background-color: #d9d9d9;
        border-radius: 24px;
      }

      .company-details-general {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #464c5c;
          text-transform: capitalize;
        }

        .location {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #747e99;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    .account-details-personal {
      .email {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #464c5c;
        text-transform: lowercase;
      }

      .phone {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #747e99;
        text-transform: lowercase;
      }
    }

    .account-type {
      display: flex;
      gap: 8px;
      align-items: center;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      color: #464c5c;
      .ri {
        width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 40px;
      }
      .ri-briefcase-fill {
        background-color: rgba(139, 69, 245, 0.12);
        color: #8b45f5;
      }
      .ri-user-3-fill {
        background-color: rgba(245, 175, 69, 0.12);
        color: #f5af45;
      }
    }

    .account-creation-details {
      .creation-day {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #464c5c;
      }

      .creation-time {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #747e99;
      }
    }

    .account-actions {
      display: flex;
      gap: 16px;
      align-items: center;
      button,
      .ri {
        cursor: pointer;
      }

      .circle-loader{
        width: auto;
      }
      
      .ri {
        color: #747e99;
      }

      button {
        border-radius: 32px;
        padding: 4px 12px;
        font-size: 12px;
        color: rgba(60, 101, 214, 1);
        background-color: rgba(60, 101, 214, 0.06);
        border: none;
      }
    }

    .session-status {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      flex-wrap: wrap;
    }
  }

  .user-modal_footer {
    padding: 16px 24px;
    border-top: 1px solid #d7dff5;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn-default {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: #ebeef5;
    }
  }
}
