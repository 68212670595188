.super-admin-wrapper {
  padding: 24px;
}

.auction-date-flex-center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -5px;
  gap: 4px;
}

.counter-circle {
  margin-inline: 5px;
  width: 40px !important;
}

.super-admin-wrapper {
  .react-table-responsive__body tr td button {
    width: 60px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    border: none;
    background: #49b876;
    color: #ffffff;
    margin: 6px 3px;
    min-height: unset;
    .button-label-txt {
      font-size: 11px !important;
    }
    &.approve {
      display: flex;
      justify-content: center;
    }
    &.reject {
      display: flex;
      justify-content: center;
      background: #f5454512;
      color: #f55353;
    }
  }
  .ri-history-line {
    font-size: 20px;
    color: #8c97b8;
  }
  .auction-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }


  .circleDocs {
    display: flex;
    position: relative;

    img {
      max-width: 20px;
    }
  }
  .circleDoc {
    position: relative;
    margin-left: -12px;

    &:first-child {
      margin: 0;
    }
    &:last-child {
      cursor: pointer;
    }
  }
  .circleDocS,
  .moreDoc--list-img {
    width: 40px !important;
    height: 40px;
    border-radius: 40px;
    background: #3d3d3d0f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moreDoc-list {
    position: absolute;
    top: 100%;
    left: -100px;
    padding: 8px 12px 8px 12px;
    width: 280px;
    z-index: 9999;
    background: white;
    border: 1px solid #d7dff5;
    border-radius: 6px;

    .arrow {
      border-right: 3px solid #131010;
      position: absolute;
      left: 73%;
      top: 0;
    }

    .arrow:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e7e5e5;
      position: absolute;
      top: -12px;
      left: -9px;
    }
  }
  .moreDoc--list {
    display: flex;
    align-items: center;
    color: #747e99;
    span {
      width: 4px;
      height: 4px;
      background: #b0a4a4;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
  .moreDoc--list-img {
    margin-right: 8px;
  }
  .moreDoc-list-wrapper {
    max-height: 200px;
    overflow-y: scroll;
  }
  .startTime {
    color: #747e99;
    font-size: 12px;
  }
}

.approval-auction-detail {
  width: 768px;
  h2 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
  }
  h3 {
    font-size: 16px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  img {
    max-height: 188px;
    width: 100%;
    object-fit: cover;
  }
  .auction-img {
    margin: 24px -24px 0;
    padding: 24px;
    border-top: 1px solid #d7dff5;
  }
  .auction-row {
    display: flex;
    padding: 12px 0;
  }
  .auction--row {
    width: 50%;
  }
  .loan-details {
    border-top: 1px solid #d7dff5;
    padding-top: 12px;
  }
  .supporting-doc {
    padding-top: 12px;
    border-top: 1px solid #d7dff5;
  }
  .support-doc {
    display: flex;
    margin-top: 16px;
  }
  .support--doc {
    width: 50%;
    display: flex;
    color: #2f323d;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    gap: 16px;
    .doc-img {
      width: 40px;
      border: 1px solid #d7dff5;
      border-radius: 50%;
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 20px;
    }

    &_size {
      color: #5d657a;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .action-btns {
    display: flex;
    margin: 24px -24px 0;
    padding: 12px 24px 0;
    border-top: 1px solid #d7dff5;
    justify-content: end;
    gap: 12px;
    button {
      width: 120px;
      height: 48px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      &.reject {
        background: rgba(245, 83, 83, 0.06);
        color: #f55353;
      }
      &.approve {
        color: #fff;
        background: #33b87a;
      }
    }
  }
}
.reject-auction-detail {
  width: 733px;
  h3 {
    font-size: 16px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
  }
  .reject--auction {
    margin: 24px -24px 0;
    padding: 24px;
    border-top: 1px solid #d7dff5;
    color: #5d657a;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #d7dff5;
    .doc-img {
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        max-width: 20px;
      }
    }
    .dot {
      width: 4px;
      height: 4px;
      background: #000;
      opacity: 0.24;
      border-radius: 50px;
    }
    .support-action {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    button {
      border: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 4px 12px;
      border-radius: 4px;
      &:first-child {
        color: #777e90;
      }
      &:nth-child(2) {
        background: rgba(60, 101, 214, 0.06);
        color: #3c65d6;
      }
    }
    .support-doc {
      border-bottom: 1px solid #d7dff5;
      &:last-child {
        border-bottom: none;
      }
    }
    .cooment-add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
    }
    .support--doc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      &_name {
        color: #2f323d;
        white-space: nowrap;
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .input {
      margin: 4px 0 12px;
    }
    .input__group {
      height: 72px;
      border-radius: 4px;
      .input__text-field {
        border: 1px solid #d7dff5;
        background: transparent;
      }
    }
  }
  .action-btns {
    display: flex;
    padding: 12px 0px 0;

    justify-content: end;
    gap: 12px;
    button {
      width: 120px;
      height: 48px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      &.reject {
        color: white;
        background: #f55353;
      }
      &.approve {
        color: #464c5c;
        background: #ebeef5;
      }
    }
  }
}

.auction-history {
  width: 768px;
  .history-header {
    border-bottom: 1px solid #d7dff5;
    margin: 0 -24px;
    padding: 0 24px 12px;
  }
  .support--doc {
    display: flex;

    align-items: center;
    padding: 6px 0;
    max-width: 100%;
    gap: 12px;
    &_name {
      color: #2f323d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
  }
  .doc-img {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .doc--img {
    background: rgba(61, 61, 61, 0.06);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .dot {
    width: 4px;
    height: 4px;
    background: #000;
    opacity: 0.24;
    border-radius: 50px;
  }
  .support-action {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 12px;
    border-radius: 50px;
    background: rgba(60, 101, 214, 0.06);
    color: #3c65d6;
  }
  h2 {
    font-size: 20px;
    color: #2f323d;
    line-height: 28px;
    font-weight: 600;
  }
  p {
    color: #5d657a;
    font-size: 14px;
    line-height: 20px;
  }
  .history-log {
    padding: 24px 0 0;
    min-height: calc(-376px + 100vh);
    .history--log:last-child {
      .history-line {
        display: none;
      }
    }
    .action {
      color: #2f323d;
      font-weight: 600;
      line-height: 20px;
    }
    .history-row {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      color: #7c8398;
    }
    .history-icon {
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: rgba(139, 69, 245, 0.12);
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
    }
    i {
      color: #8b45f5;
      font-size: 17px;
    }
    .history-date {
      color: #747e99;
      font-size: 12px;
      font-weight: 500;
    }
    .history-line {
      width: 1px;
      background: #d7dff5;
      height: 60px;
    }
    .history-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 12px;
    }

    .history--log.approved {
      i {
        color: #389f75;
      }
      .history-icon {
        border: 1px solid rgba(0, 0, 0, 0.12);

        background: rgba(51, 184, 122, 0.12);
      }
    }
  }
}

.auction-lockin{
  width: 768px;
}

.ri-settings-5-line{
  font-size: 22px;
  color: #5C5C5C;
}
.auction-row .progress-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  appearance: none;
}

.progress-bar::-webkit-progress-bar {
  background-color:  rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.progress-bar-red::-webkit-progress-value {
  background-color: #CA1616;
  border-radius: 10px;
}
.progress-bar-green::-webkit-progress-value {
  background-color: #29A359;
  border-radius: 10px;
}

.progress-values {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.9em;
  margin-top: 5px;
  color: #555; 
}

.progress-values span {
  text-align: center;
}
