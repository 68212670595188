.chat-screen__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 85vh;

  .chat-screen__header {
    padding: 8px 16px 8px 16px;
    gap: 12px;
    border-bottom: 1px solid #0000001F;
    display: flex;
    align-items: center;

    .image-container {
      position: relative;
      display: inline-block;

      /* Circle dot styling */
      .circle-dot {
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: #109345;
        border-radius: 50%;
        top: 85%;
        /* Adjust this value to position the dot vertically */
        left: 85%;
        /* Adjust this value to position the dot horizontally */
        transform: translate(-50%, -50%);
        /* Center the dot exactly */
        border: 2px solid white;
        /* Optional: adds a border around the dot */
      }

    }

    .name-initial {
      width: 48px;
      height: 48px;
      background-color: #D6D6D6;
      font-size: 19px;
      font-weight: 600;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #1F1F1F;
      text-transform: capitalize;
    }
  }

  .chat-screen__body {
    padding: 10px;
    display: flex;
    overflow-y: auto;
    height: 100%;
    flex-direction: column-reverse;

    .chat-date {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 12px;
      margin: 10px 50px;
      color: #000;
      height: 100px;
    }
    .chat-date::before,
    .chat-date::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #000;
      margin: 0 10px;
    }
    .chat-date::before {
      margin-right: 10px;
    }

    .chat-date::after {
      margin-left: 10px;
    }

    .chat-user {
      display: flex;
      align-self: flex-end;
      text-align: center;
      padding: 6px;
      gap: 10px;

      .text {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 4px 12px;
        gap: 8px;
        max-width: 468px;
        overflow: auto;
        background-color: #F5F5F5;

        .text-message {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #1F1F1F;
          text-wrap: wrap;
          text-align: left;
        }

        .chat-time {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          align-self: flex-end;
        }
      }

      .chat-initial {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #D6D6D6;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
        color: #3D3D3D;
        justify-content: center;
        align-items: center;
        justify-self: flex-end;
        align-self: flex-end;
      }
    }

    .row-reverse {
      flex-direction: row-reverse;
      align-self: flex-start;
    }
  }

}
