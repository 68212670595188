.icon-btn {
  background: var(--color-transparent);
  outline: none;
  border: none;
  height: 40px;
  width: 40px;
  cursor: pointer;
  &--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.icon {
  &__primary {
    color: var(--color-text-40-light);
    &:hover {
      color: var(--color-text-90-light);
    }
  }
  &__danger {
    color: var(--color-danger-dark);
  }
  &__secondary {
    color: var(--color-text-60-light);
  }
  &__large {
    font-size: var(--font-size-6);
  }
  &__small {
    font-size: var(--font-size-5);
  }
  &__primary.icon-btn {
    &--disabled {
      &:hover {
        color: unset;
      }
    }
  }
}
