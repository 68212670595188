@import "../../../../styles//helpers.sass";

.mobile-link {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  &__wrapper {
    max-width: 576px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // background: #282a2e;
    padding: calc(var(--base-padding) * 3) calc(var(--base-padding) * 2);
    border-radius: calc(var(--base-border-radius) * 0.5);
  }
  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  &__vertical-space {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__row-1 {
    align-items: center;
    justify-content: center;
  }
  &__title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $dark;
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $dark;
    width: 100%;
  }
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 56px;
    height: 56px;
    background: #f2f4f7;
    border: 1px solid var(--color-input-border-light);
    border-radius: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1b1c1f;
  }
  &__specification {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #747e99;
  }
  &__mobile-number {
    color: $dark;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  button {
    background: rgba(51, 184, 122, 0.06);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    color: #33b87a;
    width: 100%;
    font-size: 16px;
  }
}

.resend{
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: $dark;
  width:100%;
  text-align: center;
  padding: 12px 24px 12px 24px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  .sendCodeTimer{
    color: #0051CC;
    margin-left: 8px;
  }

}

.editBtn { 
  cursor: pointer;
  margin-left:4px;
  text-decoration: underline ;
  width: 36px ;
  // color: #F5C462;
  color: #0051CC;
  font-weight: 500;

  &:hover{
    color: $p1 ;
    font-weight: 700;
  }
}