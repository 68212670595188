.no-data {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 120px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  &__image-box {
    max-width: 100px;
    max-height: 100px;
    min-height: 80px;
    min-width: 80px;
    display: flex;
  }
  &__message {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-80-light);
  }
  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-60-light);
  }
}
