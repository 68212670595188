.skeleton_container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 50px;
}
.skeleton-loader-light {
	width: 100%;
	height: 20px;
	border-radius: 50px;
	display: block;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#adb0b8;
	background-repeat: repeat-y;
	background-size: 20px 50px;
	background-position: 0 0;
	animation: shine 2s infinite;
}

@keyframes shine {
	to {
		background-position: 100% 0, /* move highlight to right */ 0 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.skeleton-loader-dark {
	width: 100%;
	height: 8px;
	border-radius: 50px;
	display: block;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.1) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#5e626b;
	background-repeat: repeat-y;
	background-size: 20px 50px;
	background-position: 0 0;
	animation: shine 2s infinite;
}
@keyframes shine {
	to {
		background-position: 100% 0, /* move highlight to right */ 0 0;
	}
}

.skeleton_Header {
	height: 45px;
	width: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	&_upper {
		width: 80%;
		height: 12px;
	}
	&_lower {
		width: 100%;
		height: 12px;
	}
}

.skeleton_logo {
	height: 100%;
}

.skeleton_WatchList {
	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;
	padding: 14px 16px;
	gap: 8px;
	&_asset {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 80%;
	}
	&_container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		&_last {
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: flex-end;
		}
	}

	&_symbol {
		width: 60px;
	}
	&_amount {
		width: 80px;
	}
	&_change {
		width: 100px;
	}
	&_detail {
		width: 150px;
	}
	&_star {
		width: 25px;
		height: 25px;
	}
	&_star_left {
		width: 25px;
		height: 25px;
		margin-left: 8px;
	}

	&_liner {
		width: 82%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.skeleton_account_data {
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 767px) {
		flex-wrap: wrap;
		.skeleton_WatchList_amount {
			width: 40%;
			margin: 6px 0;
		}
	}
}
.skeleton_Wallet {
	display: flex;
	align-items: center;
	width: 100%;
	height: 80px;
	padding: 16px 20px 20px 16px;
	margin-bottom: 16px;
	border-radius: 8px;
	&_asset {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 80%;
	}
	&_container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
		&_last {
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: flex-end;
		}
	}

	&_symbol {
		width: 60px;
	}
	&_amount {
		width: 80px;
	}
	&_change {
		width: 100px;
	}
	&_detail {
		width: 150px;
	}
	&_star {
		width: 48px;
		height: 48px;
		margin-right: 16px;
	}
	&_star_left {
		width: 25px;
		height: 25px;
		margin-left: 8px;
	}

	&_liner {
		width: 82%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.skeleton_table {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 6px;
	width: 100%;
	height: 50px;
	border-bottom: 1px solid var(--color-gray-1);
	position: relative;

	&_symbol {
		min-width: 80px;
		max-width: 120px;
		height: 12px;
	}
}

.skeleton_body {
	width: 100%;
}
.sub_table_div {
	padding-left: 8px;
}
.skelenton-loader-line {
	height: 10px;
}
