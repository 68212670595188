.overlay {
  background-color: hsla(0, 0%, 0%, 0.3);
  &--show {
    display: block;
  }
}

.drawer {
  height: 100vh;
  background-color: var(--color-bg-100-light);
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: 999999;
  right: -300px;
  transition: 240ms ease-in-out;
  width: 300px;
  &--open {
    right: 0px;
  }
  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: var(--font-size-6);
    }
  }
  &__body {
    height: 100vh;
    padding-top: 52px;
    background: #f5f5f5;
  }
}
