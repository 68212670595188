@import styles/helpers

.wrapper
    width: 100%
    height: 100vh
    place-items: center
    display: grid
    background: $white

.inner_wrapper
    max-width: 576px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +m
        max-width: 576px
        padding: 0px 9px
        width: 100%

.inner
    background: $white
    border-radius: 8px
    padding: 48px 32px
    width: 100%
    display: flex
    flex-direction: column
    gap: 16px

.title
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: $dark

.sub_title
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $n15
.lists
    display: flex
    flex-direction: column
    gap: 16px

.list_item
    display: flex
    gap: 8px
    align-items: center
    background: rgba(51, 184, 122, 0.06)
    border-radius: 8px
    padding: 16px
    cursor: pointer
    color: $n13
    &:hover
        background: rgba(245, 175, 69, 0.06)
        color: $s5

.left_icon
    background: rgba(51, 184, 122, 0.12)
    border-radius: 24px
    width: 40px
    height: 40px
    display: grid
    place-items: center
    font-size: 24px

.right_icon
    font-size: 24px

.method_title
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: $dark
    flex: 1

.back_title
    font-weight: 500
    font-size: 14px
    line-height: 24px
    color: $dark
    flex: 1


.back_btn
    display: flex
    gap: 8px
    width: 100%
    align-items: center
    background: #EBEEF5
    border-radius: 8px
    padding: 16px
    text-align: center
    height: 48px
    align-self: center
    cursor: pointer
    color: $n13
    font-weight: 500
    font-size: 14px
    line-height: 24px
    &:hover
        background: rgba(245, 175, 69, 0.06)
        color: $s5

.edit_Btn
    cursor: pointer
    text-decoration: underline
    text-align: center
    width: 36px
    &:hover
        color: $p1
        font-weight: 700
    +s
        display: inline-block

.edit_text
    display: flex
    color: $n15
    +s
        display: inline-block
        text-align: center
